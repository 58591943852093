import React from 'react';
import FAQComponent from '../components/FAQComponent'; // Adjust the import path based on your project structure

const FaqPage = () => {
  return (
    <div className="faq-page-container">
      <header className="faq-header">
        <h1>Frequently Asked Questions</h1>
        <p>Find answers to the most commonly asked questions.</p>
      </header>
      
      <FAQComponent /> {/* FAQ component rendering */}

    </div>
  );
};

export default FaqPage;

// Sleek, minimalist CSS styling for FaqPage
const styles = `
  .faq-page-container {
    max-width: 1000px;
    margin: 0 auto;
    padding: 0.5rem;
    font-family: 'Helvetica Neue', sans-serif;
  }

  .faq-header {
    text-align: center;
    margin-bottom: 2rem;
  }

  .faq-header h1 {
    font-size: 2rem;
    color: #333;
  }

  .faq-header p {
    font-size: 1.2rem;
    color: #666;
  }
`;

// Dynamically add styles for FaqPage
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
