import React, { useState, useRef } from 'react';
import '../styles/SupportFeedback.css';
import axios from 'axios';
import { FaMicrophone, FaPaperPlane, FaStop, FaSpinner } from 'react-icons/fa';

const SupportFeedback = () => {
  const [message, setMessage] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);  // Store chat messages
  const [isSending, setIsSending] = useState(false);     // Sending state for form submission
  const mediaRecorderRef = useRef(null);

  // Parse the user ID from localStorage
  const userId = JSON.parse(localStorage.getItem('user'))?._id || '';

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const startRecording = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
          const mediaRecorder = new MediaRecorder(stream);
          mediaRecorderRef.current = mediaRecorder;
          mediaRecorder.start();
          setIsRecording(true);

          const audioChunks = [];
          mediaRecorder.ondataavailable = (event) => {
            audioChunks.push(event.data);
          };

          mediaRecorder.onstop = () => {
            const audioBlob = new Blob(audioChunks, { type: 'audio/wav' });
            setAudioBlob(audioBlob);
            setChatMessages(prev => [...prev, { type: 'audio' }]); // Add audio message placeholder
          };
        })
        .catch(err => console.error("Microphone access denied", err));
    } else {
      alert('Microphone not supported by the browser');
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSending(true); // Start loading state

    const formData = new FormData();
    formData.append('message', message);
    formData.append('_id', userId);  // Include user ID in the request

    if (audioBlob) {
      formData.append('audio', audioBlob, 'feedback.wav');
    }

    try {
      const response = await axios.post('https://ampcs.onrender.com/api/submit-feedback', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });

      console.log('Feedback submitted:', response.data);

      // Add sent message to chat and reset form states
      setChatMessages(prev => [...prev, { type: 'text', content: message }]);
      setMessage('');
      setAudioBlob(null);
    } catch (error) {
      console.error('Error submitting feedback:', error);
    } finally {
      setIsSending(false); // Stop loading state
    }
  };

  return (
    <div className="support-feedback-container">
      <div className="support-header">
        <h2>Chat Support</h2>
      </div>
      <div className="feedback-body">
        <div className="chat-display">
          {chatMessages.map((msg, index) =>
            msg.type === 'text' ? (
              <div key={index} className="chat-message">
                {msg.content}
              </div>
            ) : (
              <div key={index} className="chat-message audio-message">
                <FaMicrophone /> Audio Message Sent
              </div>
            )
          )}
        </div>

        <form onSubmit={handleSubmit} className="feedback-form">
          <textarea
            placeholder="Type your message..."
            value={message}
            onChange={handleInputChange}
            className="feedback-textarea"
            disabled={isSending}  // Disable while sending
          />
          <div className="feedback-actions">
            <button
              type="submit"
              className={`send-button ${isSending ? 'sending' : ''}`}
              title="Send"
              disabled={isSending}  // Disable while sending
            >
              {isSending ? <FaSpinner className="spinner" /> : <FaPaperPlane />}
            </button>
            <button
              type="button"
              className={`mic-button ${isRecording ? 'recording' : ''}`}
              onClick={isRecording ? stopRecording : startRecording}
              title={isRecording ? 'Stop Recording' : 'Start Recording'}
            >
              {isRecording ? <FaStop /> : <FaMicrophone />}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SupportFeedback;
