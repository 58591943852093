import React from 'react';
import SupportFeedback from '../components/SupportFeedback'; // Assuming SeeCallers component is in the same directory

const SupportFeedbackPage = () => {
  return (
    <div>
      <SupportFeedback />
    </div>
  );
};

export default SupportFeedbackPage;
