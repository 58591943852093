import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BusinessCardForm from '../components/BusinessCardForm'; // Adjust the path based on your file structure
import UserProfile from '../components/BusinessCardProfile.jsx'; // Adjust the path based on your file structure


const BusinessCentrePage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Retrieve the user object from local storage
        const user = JSON.parse(localStorage.getItem('user'));

        // If no user object is found, redirect to the login page
        if (!user) {
            navigate('/login', { state: { from: '/business-centre' } });
            return;
        }

        // Check if the user is subscribed
        if (!user.isSubscribed) {
            navigate('/'); // Redirect to the home page if not subscribed
            return;
        }

    }, [navigate]);

    return (
        <div className="business-centre-page">
            <h3 className="page-title" style={{ marginTop: '90px', marginBottom : '13px' }}>Service set-up Centre</h3>
            <UserProfile />
            <BusinessCardForm />
        </div>
    );
};

export default BusinessCentrePage;
