import React, { useState, useEffect } from 'react';
import UpgradeToUnlimitedCalls from '../components/Subs'; // Import the AboutUs component
import ContactNumbers from '../components/ContactNumbers';

const UpgradePage = () => {
  const [servicemen, setServicemen] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);

  // Fetch servicemen data from the API
  const fetchServicemen = async () => {
    try {
      const response = await fetch('https://ampcds.onrender.com/api/auth/servicemen');
      const data = await response.json();
      
      // Assuming the data format is { servicemen: [{ fullName, businessName, serviceRendered, ... }, ...] }
      setServicemen(data.servicemen);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching servicemen:', error);
      setLoading(false);
    }
  };

  // Load more servicemen when the user scrolls
  const loadMoreServicemen = () => {
    if (hasMore) {
      // Load more from the API or any local logic if needed
      // Here you could implement pagination or limit loading more
      setHasMore(false); // Disable further loading for simplicity; update as needed
    }
  };

  // Simulating a scroll event to trigger loading more servicemen
  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100) {
        loadMoreServicemen();
      }
    };

    window.addEventListener('scroll', handleScroll);
    
    // Fetch servicemen data on initial render
    fetchServicemen();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h2 style={{ margin: '20px 0' }}>
        Life makes more sense when you don't find jobs!
      </h2>
      <p style={{ marginBottom: '40px' }}>
        We understand that jobs mean money, we send jobs via SMS.
      </p>
<div style={{ marginTop: '40px', textAlign: 'center' }}>
        <UpgradeToUnlimitedCalls /> {/* Render the AboutUs component */}
        <ContactNumbers />
      </div>
      <div style={{ marginTop: '20px', padding: '20px', border: '1px solid #ccc', borderRadius: '8px', backgroundColor: '#f9f9f9' }}>
        <h3>List of Servicemen Currently Supported on Our Platform:</h3>
        {loading ? (
          <p>Loading servicemen...</p>
        ) : (
          <ul style={{ listStyleType: 'none', padding: 0 }}>
            {servicemen.map((serviceman) => (
              <li key={serviceman._id} style={{ margin: '10px 0', padding: '10px', border: '1px solid #ddd', borderRadius: '5px' }}>
                <strong>
                  {serviceman.businessName ? serviceman.businessName : serviceman.fullName}
                </strong>
                <span style={{ display: 'block', marginTop: '5px', color: '#555' }}>
                  {serviceman.serviceRendered}
                </span>
              </li>
            ))}
          </ul>
        )}
        {hasMore && !loading && <p>Loading more servicemen...</p>}
      </div>

      
    </div>
  );
};

export default UpgradePage;
