import React, { useState, useEffect } from 'react';
import axios from 'axios';
import FormatText from './FormatText'; // Importing the FormatText component

// FAQ Component
const FAQComponent = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch FAQs from the endpoint
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await axios.get('https://ampcds.onrender.com/api/faqs');
        setFaqs(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
        setLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  const [expanded, setExpanded] = useState(null);

  const toggleExpand = (id) => {
    setExpanded(expanded === id ? null : id);
  };

  if (loading) {
    return <div className="loading">Loading FAQs...</div>;
  }

  return (
    <div className="faq-container">
      {faqs.map((faq) => (
        <div key={faq.id} className="faq-item">
          <div className="faq-header" onClick={() => toggleExpand(faq.id)}>
            <h3 className="faq-title">{faq.question}</h3>
            <span className={`arrow ${expanded === faq.id ? 'expanded' : ''}`}>&#x25B6;</span>
          </div>
          {expanded === faq.id && (
            <div className="faq-content">
              <FormatText text={faq.answer} /> {/* Using FormatText for answer formatting */}
              <p className="faq-created-by">Created by: {faq.createdBy}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FAQComponent;

// Modern CSS styling
const styles = `
  .faq-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 800px;
    margin: 10px auto;
    width: 100%;
    padding: 1rem;
    font-family: 'Helvetica Neue', sans-serif;
  }

  .faq-item {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
    cursor: pointer;
  }

  .faq-item:hover {
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
  }

  .faq-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    align-items: center;
  }

  .faq-title {
    font-size: 1.2rem;
    color: #333;
    font-weight: bold;
    margin: 0;
  }

  .arrow {
    transition: transform 0.2s ease;
  }

  .arrow.expanded {
    transform: rotate(90deg);
  }

  .faq-content {
    padding: 0 1rem 1rem;
  }

  .faq-created-by {
    font-size: 0.9rem;
    color: #888;
    margin-top: 1rem;
  }

  .loading {
    text-align: center;
    font-size: 1.25rem;
    color: #999;
    margin-top: 2rem;
  }
`;

// Add styles dynamically
const styleSheet = document.createElement('style');
styleSheet.type = 'text/css';
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);
