import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { FiPhone, FiLock } from 'react-icons/fi'; // Import icons
import '../styles/AppleForm.css';

const LoginForm = () => {
  const navigate = useNavigate();
  const location = useLocation();

    
  
  const [formData, setFormData] = useState({
    phoneNumber: '',
    password: '',
  });
  const [resetPhone, setResetPhone] = useState('');
  const [formError, setFormError] = useState('');
  const [backendResponse, setBackendResponse] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isResetting, setIsResetting] = useState(false);
  const [isResetFormVisible, setIsResetFormVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleResetChange = (e) => {
    setResetPhone(e.target.value);
  };

  const isFormValid = () => {
    return Object.values(formData).every((value) => value.trim() !== '');
  };

  const handleLoginClick = async (e) => {
  e.preventDefault();
  setIsLoading(true);
  
  try {
    const response = await axios.post('https://ampcds.onrender.com/api/auth/login', formData);
    localStorage.setItem('user', JSON.stringify(response.data.user));

    // After successful login, check if the user is verified
    if (response.data.user.isVerified) {
      // Check if the user is subscribed
      if (!response.data.user.isSubscribed) {
        // Redirect to the upgrade page if not subscribed
        navigate('/upgrade-page');
      } else {
        // Redirect to the path the user intended to visit or to the home page
        const redirectTo = location.state?.from || '/';
        navigate(redirectTo);
      }
    } else {
      // If the user is not verified, redirect to the verification page
      navigate('/verification');
    }

    setBackendResponse(`Login successful! Account Number: ${response.data.user.accountNumber}`);
    setFormError('');
  } catch (error) {
    setFormError(error.response ? error.response.data.error : 'Check your network and try again.');
    setBackendResponse('');
  } finally {
    setIsLoading(false);
  }
};

  const handleCreateAccountClick = () => {
    navigate('/signup');
  };

  const handleResetPasswordClick = () => {
    setIsResetFormVisible(!isResetFormVisible);
  };

  const handleResetSubmit = async (e) => {
    e.preventDefault();
    if (resetPhone.length < 11) {
      setFormError('Phone number must be at least 11 digits.');
      return;
    }

    setIsResetting(true);

    try {
      await axios.post('https://ampcds.onrender.com/jobs/request-password-reset', { phoneNumber: resetPhone });
      setFormError('');
      alert('Reset link sent successfully.');
      setIsResetFormVisible(false);
    } catch (error) {
      setFormError('Failed to send reset link.');
    } finally {
      setIsResetting(false);
    }
  };

  return (
    <form className="apple-form" onSubmit={handleLoginClick} style={{ marginTop: '90px', marginBottom: '100px' }} >
      <h2>Welcome back!</h2>
      {formError && <p className="form-error">{formError}</p>}
      {backendResponse && <p className="backend-response">{backendResponse}</p>}
      
      <div className="form-group">
        <div className="input-container">
          <FiPhone className="input-icon" />
          <input
            type="tel"
            id="phoneNumber"
            name="phoneNumber"
            placeholder="Phone number"
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <div className="form-group">
        <div className="input-container">
          <FiLock className="input-icon" />
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Password"
            onChange={handleChange}
            required
          />
        </div>
      </div>

      <button
        type="submit"
        disabled={!isFormValid() || isLoading}
        className={isFormValid() ? 'apple-blue-button' : 'apple-blue-button disabled'}
      >
        {isLoading ? 'Logging in...' : 'Login'}
      </button>

      <div className="form-links">
        <button type="button" onClick={handleResetPasswordClick} className="forgot-password">
          Reset Password
        </button>
        <button type="button" onClick={handleCreateAccountClick} className="congo">
          Create New Account
        </button>
      </div>

      {isResetFormVisible && (
        <div className="reset-form">
          <h3>Reset Password</h3>
          <input
            type="tel"
            placeholder="Enter your phone number"
            value={resetPhone}
            onChange={handleResetChange}
          />
          <button
            type="button"
            onClick={handleResetSubmit}
            disabled={isResetting}
            className="apple-blue-button"
          >
            {isResetting ? 'Submitting...' : 'Submit'}
          </button>
          <button type="button" onClick={() => setIsResetFormVisible(false)} className="forgot-password">
            Cancel
          </button>
        </div>
      )}
    </form>
  );
};

export default LoginForm;
