import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Footer from './components/Footer';  
import BecomeServicemen from './components/BecomeServicemen';  // Import the component
import TeamChatPage from './pages/TeamChatPage';
import FAQPage from './pages/FAQPage';
import SupportFeedbackPage from './pages/SupportFeedbackPage';

// Your page components
import Home from './pages/Home';
import Dashboard from './pages/Dashboard';
import SignupForm from './components/SignupForm';
import LoginForm from './components/LoginForm';
import AboutUsPage from './pages/AboutUsPage';
import Profile from './pages/UserProfile';
import JobListPage from './pages/JobListPage';
import PostJobPage from './pages/PostJobPage';
import JobDetailsPage from './pages/JobDetailsPage';
import UserRankingPage from './pages/UserRankingPage';
import SubConfirmation from './pages/SubConfirmation';
import UpgradePage from './pages/UpgradePage';
import CallersPage from './pages/CallersPage';
import PublicRelationsPage from './pages/PublicRelationsPage';
import AccountingPage from './pages/AccountingPage';
import LeadershipFocusDetailsPage from './pages/LeadershipFocusDetailsPage';
import LeadershipFocusPage from './pages/LeadershipFocusPage';
import PhoneNumberVerificationPage from './pages/PhoneNumberVerificationPage';
import ReviewsPage from './pages/ReviewsPage';
import PermissionRequestPage from './pages/PermissionRequestPage';
import SubConfirmations from './pages/BusinessConfirmPage';
import BankAccountVerificationPage from './pages/BankAccountVerificationPage';
import FoundationPage from './pages/FoundationPage';
import RobinPage from './pages/RobinPage';
import PostedJobPage from './pages/MyJobs';
import CreditsPage from './pages/CreditsPage';
import LinkBankAccountPage from './pages/LinkBankAccountPage';
import WithdrawalsPage from './pages/WithdrawalsPage';
import BusinessCentrePage from './pages/BusinessCentrePage';
import TermsAndConditionsPage from './pages/TermsAndConditionsPage';
import PasswordResetPage from './pages/PasswordResetPage';
import GeneralProfilePage from './pages/GeneralProfilePage';

const ErrorBoundary = ({ children }) => children;

const App = () => {
  return (
    <ErrorBoundary>
      <Router>
        <div className="app-container">
          {/* Fixed Header and Navigation */}
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 999,
              backgroundColor: 'white',
              display: 'flex',
              justifyContent: 'space-between',
              padding: '10px',
              height: '40px',
            }}
          >
            <Navigation />
            <Header />
          </div>

          {/* BecomeServicemen floating below header */}
          <BecomeServicemen />

          {/* Main content area */}
          <div style={{ paddingTop: '60px', minHeight: '100vh' }}>
            <Routes>
              <Route path="/" element={<JobListPage />} />
              <Route path="/wallet" element={<Dashboard />} />
              <Route path="/signup" element={<SignupForm />} />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/home" element={<Home />} />
              <Route path="/team-chat" element={<TeamChatPage />} />
              <Route path="/post-job" element={<PostJobPage />} />
              <Route path="/job-details/:jobId" element={<JobDetailsPage />} />
              <Route path="/ranking" element={<UserRankingPage />} />
              <Route path="/sub-confirmation" element={<SubConfirmation />} />
              <Route path="/upgrade-page" element={<UpgradePage />} />
              <Route path="/callers-page/:jobId" element={<CallersPage />} />
              <Route path="/public-relation" element={<PublicRelationsPage />} />
              <Route path="/accounting/:userId" element={<AccountingPage />} />
              <Route path="/leadership-focus" element={<LeadershipFocusPage />} />
              <Route path="/leadership-focus/:blogId" element={<LeadershipFocusDetailsPage />} />
              <Route path="/verification" element={<PhoneNumberVerificationPage />} />
              <Route path="/reviews/:callerId" element={<ReviewsPage />} />
              <Route path="/permisson-request" element={<PermissionRequestPage />} />
              <Route path="/business-plan" element={<SubConfirmations />} />
              <Route path="/bank-verification" element={<BankAccountVerificationPage />} />
              <Route path="/foundation" element={<FoundationPage />} />
              <Route path="/robin" element={<RobinPage />} />
              <Route path="/faq-page" element={<FAQPage />} />
              <Route path="/my-jobs" element={<PostedJobPage />} />
              <Route path="/credits" element={<CreditsPage />} />
              <Route path="/link-bank-account" element={<LinkBankAccountPage />} />
              <Route path="/withdrawals" element={<WithdrawalsPage />} />
              <Route path="/business-centre" element={<BusinessCentrePage />} />
              <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
              <Route path="/reset-password/:token" element={<PasswordResetPage />} />
              <Route path="/user-profile/:userId" element={<GeneralProfilePage />} />
              <Route path="/support" element={<SupportFeedbackPage />} />
            </Routes>
          </div>

          {/* Footer at the bottom of the page */}
          <Footer />
        </div>
      </Router>
    </ErrorBoundary>
  );
};

export default App;
