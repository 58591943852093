import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';

const BecomeServiceman = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    // Parse user data from localStorage
    const user = JSON.parse(localStorage.getItem('user'));

    // Check if the user is not subscribed or the isSubscribed field doesn't exist
    if (!user || user.isSubscribed === false) {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    clearTimeout(timer);

    // Set a timer to reopen the component after 3 minutes and 45 seconds (225 seconds)
    const reopenTimer = setTimeout(() => {
      setIsVisible(true);
    }, 22598000); // 225000 milliseconds = 3:45 minutes

    setTimer(reopenTimer);
  };

  useEffect(() => {
    return () => {
      // Clear the timer if the component is unmounted
      clearTimeout(timer);
    };
  }, [timer]);

  if (!isVisible) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.container}>
        <FaTimes
          style={styles.closeIcon}
          onClick={handleClose}
        />
        <h2 style={styles.title}>Become a Serviceman</h2>
        <a href="https://irunor.com/upgrade-page" onClick={handleClose} style={styles.linkText}>
          Join us and offer your skills to clients worldwide. Click here to get started!
        </a>
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  },
  container: {
    background: 'linear-gradient(to bottom right, #1d72b8, #29b6f6)', // Sleek gradient background
    padding: '20px',
    borderRadius: '12px',
    width: '90%',
    maxWidth: '400px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    fontSize: '20px',
    color: '#ffffff',
    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',
  },
  title: {
    color: '#ffffff',
    fontSize: '24px',
    marginBottom: '10px',
  },
  linkText: {
    color: '#f1f1f1',
    fontSize: '16px',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: 'color 0.2s ease-in-out',
  },
};

export default BecomeServiceman;
