import React, { useEffect, useState } from 'react';
import '../styles/BusinessCardProfile.css';

const UserProfile = () => {
    const [profile, setProfile] = useState(null);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem('user'));
                const userId = storedUser ? storedUser._id : null;

                if (!userId) throw new Error("User ID not found in local storage");

                const response = await fetch(`https://ampcds.onrender.com/jobs/user-profile/${userId}`);
                if (!response.ok) throw new Error(`Error: ${response.statusText}`);
                
                const data = await response.json();
                setProfile(data);
            } catch (err) {
                setError(err.message);
            }
        };

        fetchData();
    }, []);

    if (error) return <p className="error">Error: {error}</p>;
    if (!profile) return <p className="loading">Loading...</p>;

    return (
        <div className="user-profile">
            <h1>{profile.businessName}</h1>
            <div className="profile-details">
                <div className="detail"><span>Service Rendered:</span> {profile.serviceRendered}</div>
                <div className="detail"><span>Description:</span> {profile.serviceDescription}</div>
                <div className="detail"><span>Location:</span> {profile.currentLocation}</div>
                <div className="detail"><span>Minimum Units:</span> {profile.minUnits}</div>
            </div>
        </div>
    );
};

export default UserProfile;
