import React, { useState, useEffect } from "react";
import axios from "axios";
import { FaUserCircle } from "react-icons/fa"; // Default profile icon

const JobCard = ({ job, onRemove }) => {
  const [isDescriptionCollapsed, setDescriptionCollapsed] = useState(true);
  const [isTaken, setIsTaken] = useState(job.taken);
  const [buttonText, setButtonText] = useState("Accept");

  const toggleDescription = () => {
    setDescriptionCollapsed(!isDescriptionCollapsed);
  };

  const acceptJob = async () => {
    try {
      const response = await axios.post("https://ampcds.onrender.com/api/accept-job", {
        callersId: job.callersId,
        _id: job._id
      });

      if (response.status === 200) {
        setButtonText("Call Now");
        setIsTaken(true);
      }
    } catch (error) {
      console.error("Error accepting job:", error);
    }
  };

  const declineJob = async () => {
    try {
      const response = await axios.post("https://ampcds.onrender.com/api/decline-job", {
        callersId: job.callersId,
        _id: job._id
      });

      if (response.status === 200) {
        onRemove(job._id);
      }
    } catch (error) {
      console.error("Error declining job:", error);
    }
  };

  const callUser = () => {
    if (buttonText === "Call Now") {
      window.location.href = `tel:${job.phoneNumber}`; // Redirect to phone dialer
    }
  };

  return (
    <div className={`job-card ${isTaken ? "taken" : ""}`} style={styles.card}>
      <div style={styles.profileContainer}>
        {job.mediaUrl ? (
          <img src={job.mediaUrl} alt="Profile" style={styles.profileImage} />
        ) : (
          <FaUserCircle style={styles.defaultProfileIcon} />
        )}
      </div>
      <div style={styles.detailsContainer}>
        <h4>{job.fullName}</h4>
        <p>{job.location}</p>
        <p>
          {isDescriptionCollapsed
            ? job.description.slice(0, 60) + (job.description.length > 60 ? "..." : "")
            : job.description}
          <span
            onClick={toggleDescription}
            style={styles.toggleDescription}
          >
            {isDescriptionCollapsed ? "More" : "Less"}
          </span>
        </p>
        <p>Budget: {job.minBudget} - {job.maxBudget}</p>
      </div>
      <div style={styles.buttonContainer}>
        <button
          onClick={acceptJob}
          style={styles.acceptButton}
          disabled={isTaken}
        >
          {buttonText}
        </button>
        <button onClick={declineJob} style={styles.declineButton}>
          Decline
        </button>
        {buttonText === "Call Now" && (
          <button onClick={callUser} style={styles.callButton}>
            Call Now
          </button>
        )}
      </div>
    </div>
  );
};

const JobList = () => {
  const [jobList, setJobList] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      const userId = JSON.parse(localStorage.getItem('user'))._id; // Assuming user data is stored in local storage
      const cachedJobs = JSON.parse(localStorage.getItem('jobs'));

      if (cachedJobs) {
        setJobList(cachedJobs);
      } else {
        try {
          const response = await axios.post("https://ampcds.onrender.com/api/servicemen-jobs", {
            _id: userId
          });
          
          if (response.status === 200) {
            setJobList(response.data); // Update state with fetched jobs
            localStorage.setItem('jobs', JSON.stringify(response.data)); // Cache the response in local storage
          }
        } catch (error) {
          console.error("Error fetching jobs:", error);
        }
      }
    };

    fetchJobs();
  }, []);

  const removeJobFromList = (jobId) => {
    const updatedJobList = jobList.filter((job) => job._id !== jobId);
    setJobList(updatedJobList);
    localStorage.setItem('jobs', JSON.stringify(updatedJobList)); // Update cache after removing a job
  };

  return (
    <div>
      {jobList.map((job) => (
        <JobCard key={job._id} job={job} onRemove={removeJobFromList} />
      ))}
    </div>
  );
};

// Basic styles for the component
const styles = {
  card: {
    border: "1px solid #ccc",
    padding: "16px",
    marginBottom: "16px",
    display: "flex",
    alignItems: "flex-start",
    borderRadius: "8px",
    backgroundColor: "#fff",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)"
  },
  profileContainer: {
    marginRight: "16px"
  },
  profileImage: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
    objectFit: "cover"
  },
  defaultProfileIcon: {
    fontSize: "50px",
    color: "#ccc"
  },
  detailsContainer: {
    flex: 1
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  acceptButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    border: "none",
    padding: "10px 16px",
    cursor: "pointer",
    borderRadius: "4px",
    marginBottom: "8px"
  },
  declineButton: {
    backgroundColor: "#F44336",
    color: "white",
    border: "none",
    padding: "10px 16px",
    cursor: "pointer",
    borderRadius: "4px"
  },
  callButton: {
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    padding: "10px 16px",
    cursor: "pointer",
    borderRadius: "4px"
  },
  toggleDescription: {
    color: "#007BFF",
    cursor: "pointer",
    marginLeft: "8px"
  },
  taken: {
    opacity: 0.6
  }
};

export default JobList;
