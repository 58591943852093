import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import UserJobs from '../components/PostedJobs';
import JobList from '../components/GeneralJobs'; // Import the JobList component

const PostedJobPage = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState('userJobs'); // Default to UserJobs
  const navigate = useNavigate(); // Initialize useNavigate

  // Check localStorage for the user's subscription status
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.isSubscribed) {
      setIsSubscribed(true);
    }
  }, []);

  // Function to render the selected component with subscription applied only to JobList
  const renderComponent = () => {
    if (selectedComponent === 'userJobs') {
      return <UserJobs />;
    } else if (selectedComponent === 'jobList') {
      return isSubscribed ? (
        <JobList />
      ) : (
        <div style={styles.unsubscribedMessage}>
          <h3>You need to subscribe to view this content.</h3>
          <button style={styles.upgradeButton} onClick={() => navigate('/upgrade-page')}>
            Upgrade Now
          </button>
        </div>
      );
    }
    return null;
  };

  return (
    <div>
      {/* Sleek section for enhancing user experience */}
      <div style={styles.headerContainer}>
        <h2>Welcome to Your Dashboard</h2>
        <p>Select a section to explore:</p>
        <div style={styles.selectionButtons}>
          <button
            style={selectedComponent === 'userJobs' ? styles.activeButton : styles.button}
            onClick={() => setSelectedComponent('userJobs')}
          >
            Posted Jobs
          </button>
          <button
            style={selectedComponent === 'jobList' ? styles.activeButton : styles.button}
            onClick={() => setSelectedComponent('jobList')}
          >
            Available Jobs
          </button>
        </div>
      </div>

      {/* Render the selected component */}
      <div>
        {renderComponent()}
      </div>
    </div>
  );
};

// Basic styles for the page
const styles = {
  headerContainer: {
    backgroundColor: '#f5f5f5',
    padding: '20px',
    textAlign: 'center',
    borderRadius: '8px',
    marginBottom: '20px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
  },
  selectionButtons: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  button: {
    backgroundColor: '#007BFF',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    margin: '0 10px',
    cursor: 'pointer',
    borderRadius: '4px'
  },
  activeButton: {
    backgroundColor: '#0056b3',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    margin: '0 10px',
    cursor: 'pointer',
    borderRadius: '4px'
  },
  unsubscribedMessage: {
    textAlign: 'center',
    padding: '20px',
    backgroundColor: '#ffefef',
    borderRadius: '8px',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
  },
  upgradeButton: {
    backgroundColor: '#28a745',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    marginTop: '10px',
    cursor: 'pointer',
    borderRadius: '4px'
  }
};

export default PostedJobPage;
