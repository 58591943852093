import React from 'react';
import Accounting from '../components/Accounting'; // Assuming SeeCallers component is in the same directory
import AccountUpgrade from '../components/AccountUpgrade';
import PendingTransfers from '../components/PendingTransfers';


const AccountingPage = () => {
  return (
    <div>
      <PendingTransfers/>
            <AccountUpgrade/>
<h2 style={{ margin: '50px auto', width: '93%', textAlign: 'left', marginLeft: '10px' }}>Subscribed Users</h2>
      <Accounting />
    </div>
  );
};

export default AccountingPage;
