import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaMapMarkerAlt, FaComments, FaPhoneAlt } from 'react-icons/fa';
import CommentsComponent from '../components/Comments';
import FormatText from '../components/FormatText';
import HiringConfidence from '../components/disclaimer';
import '../styles/JobDetail.css';

const JobDetails = () => {
    const { jobId } = useParams();
    const [job, setJob] = useState(null);
    const [showComments, setShowComments] = useState(false);
    const [showPhoneNumber, setShowPhoneNumber] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [userNeedsUpgrade, setUserNeedsUpgrade] = useState(false);
    
    const navigate = useNavigate();

    useEffect(() => {
        const localJobs = JSON.parse(localStorage.getItem('jobs')) || [];
        const selectedJob = localJobs.find(job => job._id === jobId);

        if (selectedJob) {
            setJob(selectedJob);
        } else {
            fetchJobFromServer();
        }
    }, [jobId]);

    const fetchJobFromServer = async () => {
        try {
            const response = await fetch(`https://ampcds.onrender.com/jobs/get-job/${jobId}`);
            if (response.ok) {
                const jobData = await response.json();
                setJob(jobData);
                saveJobToLocalStorage(jobData);
            } else {
                console.error('Failed to fetch job details from server.');
            }
        } catch (error) {
            console.error('Error fetching job from server:', error);
        }
    };

    const saveJobToLocalStorage = (jobData) => {
        const localJobs = JSON.parse(localStorage.getItem('jobs')) || [];
        localJobs.push(jobData);
        localStorage.setItem('jobs', JSON.stringify(localJobs));
    };

    const handleCallButtonClick = async (phoneNumber, jobId) => {
        try {
            const storedUserData = getUserDataFromLocalStorage();
            if (!storedUserData) {
                navigate('/login');
                return;
            }

            if (requiresUpgrade(storedUserData)) {
                setUserNeedsUpgrade(true);
                return;
            }

            await callJobAndIncreaseCount(phoneNumber, jobId, storedUserData._id);
            window.location.href = `tel:${phoneNumber}`;
        } catch (error) {
            console.error('Error initiating call:', error);
        }
    };

    const handlePhoneNumberTap = async (phoneNumber, jobId) => {
        try {
            await handleCallButtonClick(phoneNumber, jobId);
        } catch (error) {
            console.error('Error initiating call:', error);
        }
    };

    const callJobAndIncreaseCount = async (phoneNumber, jobId, userId) => {
        const response = await fetch(`https://ampcds.onrender.com/jobs/call-job-and-increase-count/${jobId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId, phoneNumber })
        });
        if (!response.ok) {
            throw new Error('Failed to initiate call and increase count');
        }

        decrementFreeJobCount();
        await updateUserData(userId);
    };

    const getUserDataFromLocalStorage = () => {
        const storedUserData = JSON.parse(localStorage.getItem('user'));
        return storedUserData || null;
    };

    const requiresUpgrade = (userData) => {
        return userData.freeJob === 0 && !userData.isSubscribed;
    };

    const decrementFreeJobCount = () => {
        const storedUserData = getUserDataFromLocalStorage();
        if (storedUserData && storedUserData.freeJob > 0) {
            storedUserData.freeJob--;
            localStorage.setItem('user', JSON.stringify(storedUserData));
        }
    };

    const updateUserData = async (userId) => {
        const userResponse = await fetch(`https://ampcds.onrender.com/api/auth/user/${userId}`);
        const userData = await userResponse.json();
        localStorage.setItem('user', JSON.stringify(userData));
    };

    const handleCommentsComponent = () => {
        setShowComments(!showComments);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        const diffInMinutes = Math.floor((now - date) / 60000);

        if (diffInMinutes < 60) {
            return `${diffInMinutes} mins ago`;
        } else if (diffInMinutes < 1440) {
            return `${Math.floor(diffInMinutes / 60)} hrs ago`;
        } else {
            return `${Math.floor(diffInMinutes / 1440)} days ago`;
        }
    };

    if (!job) {
        return <div>Loading...</div>;
    }

    return (
        <div className="job-details-container">
            <div className="job-details-card">
                <h3 className="job-creator">{job.creator}</h3>
                <div className="job-location">
                    <FaMapMarkerAlt className="icon" />
                    <p>{job.location}</p>
                </div>
                <p className="job-date">{formatDate(job.createdAt)}</p>
                <p className="job-description">
                    <FormatText text={job.description} />
                </p>
                <div className="job-details-footer">
                    <div className="job-budget">
                        <p>₦{job.minBudget} - ₦{job.maxBudget}</p>
                    </div>
                    <div className="job-call-count">
                        <FaPhoneAlt className="icon" />
                        <p>{job.callCount} calls</p>
                    </div>
                </div>
                <div className="job-actions">
                    <button className="comments-button" onClick={handleCommentsComponent}>
                        <FaComments className="icon" />
                    </button>
                    {showPhoneNumber ? (
                        <div className="phone-number-display">
                            <button className="phone-number-hide-button" onClick={() => setShowPhoneNumber(false)}>
                                <FaPhoneAlt className="icon" />
                            </button>
                            <div className="phone-number-popup">
                                <p className="phone-number-text" onClick={() => handlePhoneNumberTap(phoneNumber, jobId)}>
                                    {phoneNumber}
                                </p>
                            </div>
                        </div>
                    ) : (
                        <button className="call-button" onClick={() => handleCallButtonClick(job.phoneNumber, jobId)}>
                            <FaPhoneAlt className="icon" />
                        </button>
                    )}
                </div>
                {showComments && <CommentsComponent />}
                {userNeedsUpgrade && <p className="upgrade-message">Please upgrade your account to make calls.</p>}
                <HiringConfidence />
            </div>
        </div>
    );
};

export default JobDetails;
